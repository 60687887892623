<template>

  <div>
    <b-card
      no-body
      class="mb-1"
    >
      <template #header>
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex">
            <h3 class="mb-0">
              {{ vendorData.name }} - {{ vendorData.vendor_code }}
            </h3>
            <span
              v-if="vendorData.status == 'A'"
              class="ml-2"
            > <b-badge
              pill
              variant="success"
            >{{ t('Active') }}</b-badge></span>
            <span
              v-if="vendorData.status == 'I'"
              class="ml-2"
            > <b-badge
              pill
              variant="Danger"
            >In-Active</b-badge></span>
          </div>
          <div class="d-flex ">
            <span
              v-if="vendorData.opportunity_detail"
              class="position-relative align-self-center"
            >
              <div
                class="custom-quick-tooltip-main btn-link"
              > {{ vendorData.opportunity_detail.title }} ({{ vendorData.opportunity_detail.count }})

                <div class="custom-quick-tooltip">
                  <opportunity-list-by-lead-id :vendor_id="$route.params.id" />
                </div>
              </div>
            </span>

            <b-dropdown
              v-if="vendorBtns"
              :text="t(selectUnit)"
              variant="primary"
              class="pl-1 mxh-250"
            >
              <b-dropdown-item
                v-for="vulist in vendorUnitList"
                :key="vulist.vendor_unit_id"
                @click="filterUnitid(vulist.vendor_unit_id, vulist.unit_title)"
              >
                {{ vulist.unit_title }}
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              :text="t('Actions')"
              variant="primary"
              class="pl-1"
            >
              <b-dropdown-item
                v-if="addUnitData.vendor_id"
                @click="addUnit"
              >
                Add Unit
              </b-dropdown-item>
              <b-dropdown-item
                v-if="addUnitData.vendor_id"
                @click="unitList(addUnitData.vendor_id)"
              >
                Units List
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>

      <b-card-body>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CopyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Type') }}</span>
            </th>
            <td class="pb-50">
              {{ vendorData.vendor_type_name }}
            </td>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Customer Type') }}</span>
            </th>
            <td class="pb-50">
              {{ vendorData.customer_type }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Created By') }}</span>
            </th>
            <td class="pb-50">
              {{ vendorData.created_by_name }}
            </td>
            <th class="pb-50">
              <feather-icon
                icon="LinkIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Website') }}</span>
            </th>
            <td class="pb-50">
              <a
                :href="vendorData.website"
                target="_blank"
              >{{ vendorData.website }}</a>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Landline No.') }}</span>
            </th>
            <td class="pb-50">
              {{ vendorData.landline }} <span v-if="vendorData.extention">{{ `- ${vendorData.extention}` }}</span>
            </td>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Created Date') }}</span>
            </th>
            <td class="pb-50">
              {{ vendorData.created_date }}
            </td>
          </tr>
        </table>
      </b-card-body>
    </b-card>
    <!-- <b-card> <quick-links /> </b-card> -->
    <b-row>
      <b-col xl="8">
        <b-card>
          <b-tabs
            class="vendor_details"
            content-class=""
            justified
            pills
            :vertical="verticalTab"
          >
            <b-tab
              active
              :title="t('Contacts')"
            >
              <contacts
                v-if="Object.keys(vendorData).length > 0"
                :vendor_id="activity_ids.vendor_id"
                :vendor_unit_id="activity_ids.unit_id"
                :is_edit="vendorData.is_edit ? vendorData.is_edit : 0"
              />
            </b-tab>
            <!-- <b-tab
            title="Units"
          > <units
            v-if="Object.keys(vendorData).length > 0"
            :units="vendorData.VendorAddress"
          /></b-tab> -->
            <b-tab :title="t('Documents')">
              <documents :activity_ids="activity_ids" />
            </b-tab>
            <b-tab :title="t('Reminder')">
              <reminders
                v-if="Object.keys(vendorData).length > 0"
                :activity_ids="activity_ids"
                :is_edit="vendorData.is_edit ? vendorData.is_edit : 0"
              />
            </b-tab>
            <b-tab :title="t('Remarks')">
              <remarks
                v-if="Object.keys(vendorData).length > 0"
                :activity_ids="activity_ids"
                :is_edit="vendorData.is_edit ? vendorData.is_edit : 0"
              />
            </b-tab>
            <!-- <b-tab title="Activities">
              <activities :id=" vendor_id" />
            </b-tab> -->
          </b-tabs>
        </b-card>
      </b-col>
      <b-col
        xl="4"
        class="pl-md-0 h-100"
      >
        <b-card
          id="activity"
          class="activity-h-scroll"
        >
          <activity
            v-if="Object.keys(vendorData).length > 0"
            :contacts="vendorData.VendorContact"
            :data_ids="activity_ids"
            :is_edit="vendorData.is_edit ? vendorData.is_edit : 0"
            :editactivitydata="editActivityData"
          />
          <activities
            :dataids="activity_ids"
            :is_edit="vendorData.is_edit ? vendorData.is_edit : 0"
            @getActivityById="getActivityById"
          />

        </b-card>
      </b-col>
    </b-row>
    <add-unit
      :addunitdata="addUnitData"
      @loadUnits="loadUnits"
    />
    <!-- <close-lead /> -->
  </div>
</template>

<script>
import {
  BCard, BCardBody, BTabs, BTab, BDropdown, BDropdownItem, BBadge, BRow, BCol, VBToggle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils } from '@core/libs/i18n'
import checkPermissions from '@/utils/checkPermissons'
import Contacts from './VendorTabs/Contacts.vue'
// import Units from './VendorTabs/Units.vue'
import Reminders from './VendorTabs/Reminders.vue'
import Activity from './Activity.vue'
import Remarks from './VendorTabs/Remarks.vue'
import Documents from './VendorTabs/Documents.vue'
import Activities from './VendorTabs/Activities.vue'
import { showLoader, hideLoader } from '@/utils/common'
import store from '@/store'
import AddUnit from './Units/AddUnit.vue'
// import CloseLead from './CloseLead.vue'
import constants from '@/constants'
import permissions from '@/permissions'
// import QuickLinks from './QuickLinks.vue'
import OpportunityListByLeadId from '@/views/Company/Leads/LeadDetails/OpportunityListbyLeadId.vue'

export default {
  components: {
    OpportunityListByLeadId,
    Activity,
    BCard,
    BCardBody,
    BTabs,
    BRow,
    BCol,
    BTab,
    Contacts,
    Reminders,
    BBadge,
    Remarks,
    Documents,
    Activities,
    BDropdown,
    BDropdownItem,
    AddUnit,
    // CloseLead,
    // QuickLinks,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      leadStepStatus: [],
      constants,
      permissions,
      editActivityData: [],
      activity_ids: {
        activity_for_id: this.$route.params.id,
        activity_for: constants.VENDOR,
        lead_id: '',
        vendor_id: this.$route.params.id,
        unit_id: '',
      },
      addUnitData: {
        vendor_id: '',
      },
      createdParams: {
        vendor_id: '',
        lead_id: '',
      },
      vendorData: {},
      vendorBtns: false,
      vendorUnitList: [],
      verticalTab: false,
      selectUnit: 'Select Unit',
    }
  },
  mounted() {
    showLoader()
    // eslint-disable-next-line eqeqeq
    if (this.$route.name == 'lead/id') {
      this.createdParams.lead_id = this.$route.params.id
      this.activity_ids.lead_id = this.$route.params.id
      this.activity_ids.unit_id = ''
      this.activity_ids.vendor_id = ''
      this.getLeadById()
    } else {
      this.createdParams.vendor_id = this.$route.params.id
      this.activity_ids.lead_id = ''
      this.activity_ids.unit_id = ''
      this.activity_ids.vendor_id = this.$route.params.id
      this.addUnitData.vendor_id = this.$route.params.id
      this.getVendorUnitList()
      this.vendorBtns = true
      this.getVendorById()
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.verticalTab = true
      } else {
        this.verticalTab = false
      }
    })
    if (window.innerWidth <= 768) {
      this.verticalTab = true
    } else {
      this.verticalTab = false
    }
    this.getLeadStatus()
  },
  methods: {
    checkPermission(val) {
      return checkPermissions(val)
    },
    getLeadStatus() {
      store.dispatch('vendorStore/leadStepStatus').then(response => {
        this.leadStepStatus = response.data.data
      })
    },
    async getActivityById(id) {
      showLoader()
      await store.dispatch('activityStore/getActivityById', { activity_id: id }).then(response => {
        this.editActivityData = response.data.data
        document.getElementById('activity').scrollTop = 0
      })
      hideLoader()
    },
    getVendorById() {
      store.dispatch('vendorStore/getVendorById', this.createdParams).then(response => {
        this.vendorData = response.data.data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    async getLeadById() {
      await store.dispatch('vendorStore/getLeadById', this.createdParams).then(response => {
        this.vendorData = response.data.data
        hideLoader()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    async getVendorUnitList() {
      await store.dispatch('vendorStore/getVendorUnitList', this.createdParams).then(response => {
        this.vendorUnitList = response.data.data.data
        hideLoader()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    filterUnitid(unitId, unitTitle) {
      this.selectUnit = unitTitle
      this.activity_ids.activity_for = constants.VENDOR_UNIT
      this.activity_ids.unit_id = unitId
      showLoader()
      store.dispatch('vendorStore/getVendorById', { vendor_id: this.createdParams.vendor_id, vendor_unit_id: unitId }).then(response => {
        this.vendorData = response.data.data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })

      hideLoader()
    },
    // async convertToOpportunity() {
    //   showLoader()
    //   await store.dispatch('vendorStore/convertToOpportunity', this.createdParams).then(response => {
    //     // eslint-disable-next-line eqeqeq
    //     if (response.data.code == constants.SUCCESS) {
    //       console.log(response.data)
    //       this.getVendorById()
    //       this.getVendorUnitList()
    //       this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: response.data.msg,
    //           icon: 'Success',
    //           variant: 'success',
    //           text: '',
    //         },
    //       })
    //       // this.$route.push(`/vendor/`)
    //     }
    //   }).catch(() => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Something Went Wrong',
    //         icon: 'Danger',
    //         variant: 'danger',
    //         text: '',
    //       },
    //     })
    //     hideLoader()
    //   })
    //   hideLoader()
    // },
    addUnit() {
      this.$bvModal.show('addUnit')
    },
    closeLead() {
      this.$bvModal.show('closeLeadModal')
    },
    loadUnits() {
      this.getVendorUnitList()
    },
    unitList(vid) {
      this.$router.push(`/vendor/${vid}/unit-list`)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped lang="css">
@import url('https://cdn.jsdelivr.net/npm/smartwizard@5/dist/css/smart_wizard_all.min.css');
.custom-quick-tooltip-main{
  cursor: pointer;
     position: relative;
}
.custom-quick-tooltip{
  display: none;
}
/* .custom-quick-tooltip-main::after{
  content: '';
    padding-top: 10px;
    border-top: 6px  solid;
    transform: rotate(0deg) translate(50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    left: 0;
}
.custom-quick-tooltip-main::after{
    display: none;
  } */
.custom-quick-tooltip{
  position: absolute;
  width: max-content;
  height: auto;
  align-items: center;
  right: 0;
  border-radius: 5px;
  background: #ffffff;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 35%);
  z-index: 999;
}
.custom-quick-tooltip-main:hover .custom-quick-tooltip {
  display: block;
}
</style>
<style>
.mxh-250 ul.dropdown-menu{
  max-height: 250px;
  overflow-y: scroll;
}
.card {
  margin-bottom: 1rem !important;
}
.mxh-250 ul.dropdown-menu::-webkit-scrollbar{
  display: none;
}
.activity-h-scroll{
  /* height: 455px; */
  min-height: 455px;
  height: calc(100vh - 368px);
  overflow: auto;
}
.activity-h-scroll::-webkit-scrollbar{
    display: none;
}
 .nav-pills.nav-justified .nav-link {
    border: 1px solid #7367f0;
}
 .nav-pills.nav-justified .nav-item {
    display: table;
    position: relative;
}
.tabs.vendor_details .tab-pane.active:not(.tab-card-fixed-height .tabs *){
    border: 2px solid rgb(115, 103, 240);
    padding: 1rem;
    border-radius: 10px;
    overflow-y: auto;
    height: 100%;
}
.tabs.vendor_details .nav-link.active:not(.tab-card-fixed-height .tabs *){
  position: relative;
}
.tabs.vendor_details .nav-link.active:not(.tab-card-fixed-height .tabs *):after {
    content: "";
    position: absolute;
    left: auto;
    z-index: 11;
    display: block;
    width: 12px;
    height: 12px;
    background: white;
    border-top: 2px solid #7367f0;
    border-left: 2px solid #7367f0;
    top: 42px;
    right: 50%;
    transform: rotate(45deg) translateX(50%);
}
.dark-layout .tabs.vendor_details .nav-link.active:not(.tab-card-fixed-height .tabs *):after {
  background: #283046;
}
.h-70{
  height: 70px;
}
.overflow-x-scroll{
  overflow-x: scroll !important;
}
.overflow-x-scroll::-webkit-scrollbar{
  display: none;
}
.statusStep a::after {
    top: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    border-top: 35px solid transparent !important;
    border-bottom: 35px solid transparent !important;
}
.statusStep a::before {
    top: 0 !important;
    margin-top: 0 !important;
    margin-left: 1px !important;
    border-top: 35px solid transparent !important;
    border-bottom: 35px solid transparent !important;
    border-left: 30px solid #ddd;
}
.tab-pane.active {
  position: relative;
}
.tab-card-fixed-height{
    /* height: 320px; */
    min-height: 320px;
    height: calc(100vh - 504px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
}
@media (max-width: 768px) {
  .tabs.vendor_details .nav-link.active:not(.tab-card-fixed-height .tabs *):after {
    left: auto !important;
    top: 50% !important;
    right: -32px !important;
    transform: rotate(315deg) translateX(50%) !important;
}
}
</style>
